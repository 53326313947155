<template>
    <div>
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{$t('title.administrator')}} </h4>
                </div>
                <div class="col-6">
                    <div class="float-right">
                        <router-link :to="{ name: 'userEdit', params: { id: -1 } }" class="btn btn-info">{{ $t('actions.add') }}</router-link>
                    </div>
                    <div class="float-right form-group mg-b-0 mg-r-10">
                        <search :search="search" @search="doSearch"></search>
                    </div><!-- form-group -->
                </div>
            </div>
        </div>
        <div class="br-pagebody">
            <div class="br-section-wrapper">
                <div class="table-responsive">
                    <table v-if="initialized" class="table table-hover table-striped mg-b-0">
                        <thead>
                            <tr>
                                <th>{{ $t('name') }}</th>
                                <th>{{ $t('emailaddress') }}</th>
                                <th class="text-center">{{ $t('title.details') }}</th>


                            </tr>
                        </thead>
                        <tbody v-if="list.length > 0">
                            <tr v-for="record in list" :key="record.id">
                                <td>{{record | nameLong}} </td>
                                <td>{{record.email}}</td>
                                <td class="text-right" width="150">
                                    <button class="btn btn-icon btn-success mg-r-15" @click="startEdit(record.id)"><font-awesome-icon icon="edit" /></button>
                                    <button class="btn btn-icon btn-danger" @click="deleteRecord(record.id)"><font-awesome-icon icon="trash" /></button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3">{{ $t('status.norecords') }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else><em>{{ $t('status.loading') }}</em></p>
                </div>
                <paging v-if="initialized" :current-page="page" :total-pages="totalPages" @page-changed="goToPage" />
            </div><!-- br-section-wrapper -->
        </div><!-- br-pagebody -->
    </div>
</template>

<script>
    import { LIST_SEARCH, LIST_PAGE } from '@/store/actions/listactions';
    import { mapGetters, mapActions } from 'vuex';
    import toastr from 'toastr';
    export default {
        name: 'ManageUserList',
        data() {
            return {
                initialized: false,
                totalPages: 0,
                list: [],

            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            search() {
                this.fetch();
            },
            page() {
                this.fetch();
            },
        },
        methods: {
            startEdit(id) {
                this.$router.push({ name: 'userEdit', params: { id } });
            },
            async fetch() {
                if (this.$route.query.add) {
                    this.$router.push({ name: 'userEdit', params: { id: -1 } });
                }
                const client = await this.$api.getClient();
                const resp = await client.GetUsers({ page: this.page, search: this.search});
                this.list = resp.data.list;
                this.totalPages = resp.data.totalPages;
                this.initialized = true;
            },
            deleteRecord(id) {
                this.$dialog.confirm(this.$t('confirm.delete'))
                    .then(async () => {
                        const client = await this.$api.getClient();
                        try {
                            await client.DeleteUser(id);
                            toastr.success(this.$t('success.deleteRecord'), '');
                            await this.fetch();
                        } catch (err) {
                            toastr.error(this.$t('error.deleteRecord'), err);
                        }
                    })
                    .catch(() => { });
            },
            ...mapActions('userlist', {
                doSearch: LIST_SEARCH,
                goToPage: LIST_PAGE
            }),
        },
        computed: {
            ...mapGetters('userlist', [
                'page',
                'search'
            ])
        }
    }
</script>
