<template>
    <div v-if="initialized">
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{ $t('title.administrator') }} </h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <span class="breadcrumb-item">{{ $t('title.settings') }}</span>
                            <router-link class="breadcrumb-item" :to="{name:'users'}">{{ $t('title.users') }}</router-link>
                            <span class="breadcrumb-item active">{{record | nameLong}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
        </div>

        <form @submit.prevent="saveChanges">
            <div class="br-pagebody">
                <div class="br-section-wrapper">

                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ $t('actions.edit') }}</h6>
                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('email')">{{$t('email')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.email" name="email" type="email" :readonly="record.id > 0" />
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('initials')">{{$t('initials')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-validate="'required'" v-model="record.initials" name="initials" />
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('firstname')">{{$t('firstname')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.firstName" name="firstName" v-validate="'required'" />
                        </div>
                    </div>



                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('middlename')">{{$t('middlename')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.middleName" name="middleName" />
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('lastname')">{{$t('lastname')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.lastName" name="lastName" v-validate="'required'" />
                        </div>
                    </div>
                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('administrator')">{{$t('administrator')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <inputtoggle v-model="record.administrator" :value="record.administrator"></inputtoggle>
                        </div>
                    </div>
                    <div class="row mg-t-30" v-if="setpassword">
                        <div class="col-4">
                        </div>
                        <div class="col-md-4">
                            <label class="rdiobox">
                                <input class="form-control form-control-sm" name="rdio" type="radio" v-model="sendpassword" value="1">
                                <span>{{$t('sendinvitation')}}</span>
                            </label>
                        </div>
                        <div class="col-md-4">
                            <label class="rdiobox">
                                <input class="form-control form-control-sm" name="rdio" type="radio" v-model="sendpassword" value="0">
                                <span>{{$t('setpassword')}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="row mg-t-15" v-if='setpassword && sendpassword==0'>
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('password')">{{$t('password')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-validate="'required|verify_password'" ref="user.password" v-model="record.password" type="password" name="user.password" :placeholder="$t('newpassword')">
                            <p v-show="errors.has('user.password')" class="help is-danger">{{ errors.first('user.password') }}</p>
                        </div>
                    </div>
                    <div class="row mg-t-15" v-if='setpassword && sendpassword==0'>
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('confirmpassword')">{{$t('confirmpassword')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-validate="'required|confirmed:user.password'" v-model="confirmpassword" type="password" name="confirmpassword" :placeholder="$t('confirmpassword')">
                        </div>
                    </div>
                    <div class="row mg-t-30">
                        <div class="col-sm-8 mg-l-auto">
                            <div class="form-layout-footer">
                                <button class="btn btn-success mg-r-15" id="Submit" name="Submit" type="submit">{{ $t('actions.save') }}</button>
                                <button v-if="!setpassword" @click="showpassworddialog" type="button" class="btn btn-secondary">{{ $t('title.changepassword') }}</button>
                            </div><!-- form-layout-footer -->
                        </div><!-- col-8 -->
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>

    import toastr from 'toastr';
    import checkBeforeLeaveMixin from '@/mixin/index.js';
    import changepassword from '@/components/dialogs/ChangePassword.vue';
    export default {
        name: 'ManageAdministratorEdit',
        mixins: [checkBeforeLeaveMixin],
        data() {
            return {
                initialized: false,
                record: {
                    id: -1,
                    email: '',
                    initials: '',
                    password: '',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    administrator: true
                },
                active: true,
                isvalidate: false,
                confirmpassword: '',
                password: '',
                setpassword: false,
                sendpassword: 1
            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            '$route'() {
                this.fetch();
            },
            record: {
                handler() {
                    this.setDirty(true);
                },
                deep: true
            },

            confirmpassword() {
                this.isvalidate = this.errors.any();
            },
        },
        computed: {
            validatedata() {
                return this.errors.any();
            },
        },
        methods: {
            async fetch() {
                if (this.$route.params.id > 0) {
                    const client = await this.$api.getClient();
                    const resp = await client.GetUser(this.$route.params.id);
                    this.record = resp.data;
                    this.initialized = true;
                    this.$nextTick(function () { this.setDirty(false); });
                } else {
                    this.initialized = true;
                    this.setDirty(false);
                    this.setpassword = true;
                }
            },
            async saveChanges() {
                const client = await this.$api.getClient();
                try {
                    if (this.$route.params.id > 0) {
                        await client.UpdateUser(this.$route.params.id, this.record);
                    } else {
                        await client.InsertUser(null, this.record);
                    }
                    this.setDirty(false);
                    toastr.success(this.$t('success.saveChanges'), '');
                    this.$router.push({ name: 'users' });
                    this.setpassword = false;
                } catch (err) {
                    if (err.response.status === 423) {
                        toastr.error(this.$t('error.emailexists'));
                    }
                    else {
                        toastr.error(this.$t('error.saveChanges'), err);
                    }
                }
            },
            deleteRecord() {
                this.$dialog.confirm(this.$t('confirm.delete'))
                    .then(async () => {
                        const client = await this.$api.getClient();
                        try {
                            await client.DeleteUser(this.$route.params.id);
                            this.setDirty(false);
                            toastr.success(this.$t('success.deleteRecord'), '');
                            this.$router.push({ name: 'users' });
                        } catch (err) {
                            toastr.error(this.$t('error.deleteRecord'), err);
                        }
                    })
                    .catch(() => { });
            },
            showpassworddialog() {
                this.$vuedals.open({
                    title: this.$t('title.changepassword'),
                    props: {
                        id: parseInt(this.$route.params.id, 0),

                    },
                    component: changepassword,
                    size: 'md',
                    escapable: true,
                });
            },
            async unblockuser() {
                this.record.lockoutEnd = null;
                await this.saveChanges();
            }
        }
    };

</script>